import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Estilo personalizado para la tarjeta
const KPICard = styled(Card)({
    
  maxWidth: 300,
  width:"20vw",
  margin: 'auto',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
  transition: '0.3s',
  borderRadius: '10px',
  '&:hover': {
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
  },
});

const KPICardContent = styled(CardContent)({
  textAlign: 'center',
});

const KPIValue = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '2rem', // Tamaño de fuente personalizado
});

const KPIName = styled(Typography)({
  marginTop: '10px',
});

interface KPICardProps {
  value: number | string;
  name: string;
}

const KPICardComponent: React.FC<KPICardProps> = ({ value, name }) => {
  return (
    <KPICard>
      <KPICardContent>
        <KPIValue variant="h5" >
          {value}
        </KPIValue>
        <KPIName variant="body2" color="textSecondary">
          {name}
        </KPIName>
      </KPICardContent>
    </KPICard>
  );
};

export default KPICardComponent;
