// components/About.tsx
import React from 'react';

const Calendar: React.FC = () => {
    return (
        <div style={{  padding: '20px' }}>
        <h2>Calendar</h2>
        <p>Aqui Calendario.</p>
    </div>
    );
};

export default Calendar;
