// components/Contact.tsx
import React from 'react';

const Workers: React.FC = () => {
    return (
        <div style={{  padding: '20px' }}>
        <h2>Trabajadores</h2>
        <p>Contact us page content goes here.</p>
    </div>
    );
};

export default Workers;