// RectangleComponent.tsx
import React, { useState } from "react";

import "./OldEventsComponent.css";
import EventsProps from "../../../models/EventsModel";
import { Padding, ShieldTwoTone } from "@mui/icons-material";

const OldEvents: React.FC<EventsProps> = ({
  date,
  nameEvent,
  organizer,
  hourInit,
  hourEnd,
  shifts,
  realShifts,
}) => {
  return (
    <div className="oldevent-rectanglev2">
      <div className="oldevent-rectanglev2-date">
        <div style={{ fontSize: 28 }}>{date}</div>
        <div>Marzo</div>
      </div>
      <div className="oldevent-rectanglev2-detail">
        <div className="oldeventv2-header">
          {nameEvent}
        </div>
        <div className="oldeventv2-detail">
          <div> Turnos: {shifts}  </div>
          <div className="oldeventv2-pending"> Pendientes: {shifts-30}</div>
          
        </div>
      </div>
    </div>
    //Otro diseño de componente
    /* <div className="oldevent-rectangle">
      <div className="oldevent-header">
        <span className="oldevent-name">{nameEvent}</span>
        <span className="oldevent-date">{date}</span>
      </div>
      <div className="oldevent-detail" style={{ display: "flex" }}>
        <div>
          <div className="olddetail-item">
            <strong>Organizador:</strong> {organizer}
          </div>

          <div className="olddetail-item">
            <strong>Turnos:</strong> {shifts}
          </div>
          <div className="olddetail-item">
            <strong>Turnos agendados:</strong> {realShifts}
          </div>
        </div>
        <div style={{ padding: "5px" }} className="oldevent-rectangle-pending">
          <div className="olddetail-item-pending">Turnos Pendientes</div>
          <div className="olddetail-item-pending">19</div>
        </div>
      </div>
    </div> */
  );
};

interface OldEventsListProps {
  oldevents: {
    date: string;
    nameEvent: string;
    organizer: string;
    hourInit: string | null;
    hourEnd: string | null;
    shifts: number;
    realShifts: number;
  }[];
}

const OldEventsList: React.FC<OldEventsListProps> = ({
  oldevents: oldevents,
}) => {
  return (
    <div className="oldevents-list">
      {oldevents.map((newevents, index) => (
        <OldEvents
          key={index}
          date={newevents.date}
          nameEvent={newevents.nameEvent}
          organizer={newevents.organizer}
          hourInit={newevents.hourInit}
          hourEnd={newevents.hourEnd}
          shifts={newevents.shifts}
          realShifts={newevents.realShifts}
        />
      ))}
    </div>
  );
};

export default OldEventsList;
