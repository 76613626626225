// components/Home.tsx
import React from 'react';

const Dashboard: React.FC = () => {
    return (
        <div style={{  padding: '20px' }}>
        <h2>Dashboard</h2>
        <p>Welcome to the home page!</p>
    </div>
    );
};

export default Dashboard;
