import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import EventsProps from "../../../models/EventsModel";
import "./NewEventsComponent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

// Estilo personalizado para la tarjeta
const EventCard = styled(Card)({
  width: "37vw",
  height:"15vh",
  margin: "auto",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  transition: "0.3s",
  borderRadius: "10px",
  "&:hover": {
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
  },
});
const TotalCard = styled(Card)({
  display: "flex",
  width: "100%",
  overflow: "hidden",
  justifyContent: "space-between",
});
const DateCardleft = styled(Card)({
  display: "flex",
  width: "25%",
  height:"100%",
  overflow: "hidden",
  justifyContent: "space-between",
  flexDirection: "column",
});

const DateCardTop = styled(Card)({
  display: "flex",
  borderRadius:"0%",
  padding:"0",
  width: "100%",
  height: "10vh",
  overflow: "hidden",
  justifyContent: "space-between",
  backgroundColor: "#003865",
  color:"white",
  flexDirection: "column",
  alignItems:"center",
  margin:"0"

});
const DateCardBot = styled(Card)({
  display: "flex",
  borderRadius:"0% 0% 0% 0%",
  flexDirection: "row",
  alignItems:"center",
  width: "100%",
  height: "5vh",
  overflow: "hidden",
  justifyContent: "space-around",
  backgroundColor: "#0057B8",
  color:"white",
  paddingLeft:"3px",
  paddingRight:"3px"
});

const EventCardContent = styled(CardContent)({
  textAlign: "left",
});

const EventTitle = styled(Typography)({
  fontWeight: "bold",
});

const EventDate = styled(Typography)({
  fontStyle: "italic",
  marginBottom: "10px",
});

const EventCardComponent: React.FC<EventsProps> = ({
  date,
  nameEvent,
  organizer,
  hourInit,
  hourEnd,
  shifts,
  realShifts,
}) => {
  return (
    <EventCard style={{ margin: "10px" }}>
      <TotalCard>
        <EventCardContent>
          <EventTitle variant="h5">{nameEvent}</EventTitle>
          <Typography variant="body2" color="textSecondary">
            <strong>Organizador:</strong> {organizer}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            <strong>Horario:</strong> {hourInit} - {hourEnd}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Turnos:</strong> {shifts}
          </Typography>
          
        </EventCardContent>
        <DateCardleft>
          <DateCardTop>
            <span style={{fontSize:"2.5vw", margin:"0"}}>{date}</span>
            <span style={{fontSize:"1vw", margin:"0"}}>Marzo</span>
          </DateCardTop>
          <DateCardBot> <FontAwesomeIcon icon={faPeopleGroup} /> {realShifts}/{shifts}</DateCardBot>
        </DateCardleft>
        
      </TotalCard>
    </EventCard>

    //Otro diseño de componente
    /*  <EventCard style={{ margin: "10px" }}>
      <EventCardContent>
        <EventDate variant="body2" color="textSecondary">
          {date}
        </EventDate>
        <EventTitle variant="h5">{nameEvent}</EventTitle>
        <Typography variant="body2" color="textSecondary">
          <strong>Organizer:</strong> {organizer}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          <strong>Hour:</strong> {!hourInit} - {!hourEnd}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Shifts:</strong> {shifts}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Real Shifts:</strong> {realShifts}
        </Typography>
      </EventCardContent>
    </EventCard> */
  );
};
interface NewEventsListProps {
  newevents: {
    date: string;
    nameEvent: string;
    organizer: string;
    hourInit: string | null;
    hourEnd: string | null;
    shifts: number;
    realShifts: number;
  }[];
}

const NewEventsList: React.FC<NewEventsListProps> = ({
  newevents: newevents,
}) => {
  return (
    <div className="newevents-list">
      {newevents.map((newevents, index) => (
        <EventCardComponent
          key={index}
          date={newevents.date}
          nameEvent={newevents.nameEvent}
          organizer={newevents.organizer}
          hourInit={newevents.hourInit}
          hourEnd={newevents.hourEnd}
          shifts={newevents.shifts}
          realShifts={newevents.realShifts}
        />
      ))}
    </div>
  );
};

export default NewEventsList;
