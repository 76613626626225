// App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./containers/Dashboard/Dashboard";
import Calendar from "./containers/Calendar/Calendar";
import Events from "./containers/Events/Events";
import Sidebar from "./components/Sidebar/Sidebar";
import Workers from "./containers/Workers/Workers";
import { Grid, Toolbar } from "@mui/material";
import TopBar from "./components/Topbar/Topbar";
import Topbar from "./components/Topbar/Topbar";

const App: React.FC = () => {
  return (
    <Router>
      <div style={{ display: "flex" }}>
        <Grid container style={{ minHeight: "100vh" }}>
          {/* Sidebar */}
          <Grid item xs={12} md={1}>
            <Sidebar />
          </Grid>
          <Grid item xs={12} md={5}>
            <div>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/Calendar" element={<Calendar />} />
                <Route path="/Events" element={<Events />} />
                <Route path="/Workers" element={<Workers />} />
              </Routes>
            </div>
          </Grid>
        </Grid>
      </div>
    </Router>
  );
};

export default App;
