import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material/TextField";
import StaticTimePicker from "@mui/x-date-pickers/StaticTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
  TimeClock,
  TimePicker,
} from "@mui/x-date-pickers";
import "./formEvent.css";
import EventsProps from "../../../models/EventsModel";
import { faL, faPlus } from "@fortawesome/free-solid-svg-icons";
import { JSX } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AddEventFormProps {
  onAddEvent: (event: EventsProps) => void;
  onClose: () => void;
}

const AddEventForm: React.FC<AddEventFormProps> = ({ onAddEvent, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState<EventsProps>({
    date: "",
    nameEvent: "",
    organizer: "",
    hourInit: null,
    hourEnd: null,
    shifts: 0,
    realShifts: 0,
  }
  );

  /* useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]); */

  const handleHourInitChange = (newHour: string | null) => {
    setFormData({ ...formData, hourInit: newHour });
  };
  const handleHourEndChange = (newHour: string | null) => {
    setFormData({ ...formData, hourEnd: newHour });
  };

  const handleSubmit = (e: React.FormEvent) => {
    
    e.preventDefault();
    console.log(formData);
    localStorage.setItem("formData", JSON.stringify(formData));
    localStorage.setItem("nameEvent", formData.nameEvent);
    localStorage.setItem("organizer", formData.organizer);
    localStorage.setItem("Fecha", formData.date);
    const savedDataString = localStorage.getItem("formData");
    console.log(JSON.parse(savedDataString!));
    onAddEvent(formData);
    setIsOpen(false);
    onClose();
   /*  setFormData({
        date: "",
        nameEvent: "",
        organizer: "",
        hourInit: null,
        hourEnd: null,
        shifts: 0,
        realShifts: 0,
      }); */
  };
  const handleCancel = () => {
    setFormData({
      nameEvent: "",
      date: "",
      organizer: "",
      hourInit: null,
      hourEnd: null,
      shifts: 0,
      realShifts: 0,
    }); // Limpiar el formulario
    onClose();
    setIsOpen(false); // Cerrar el formulario
  };

  return (
    <div className="add-event-form-container">
      <Button variant="contained" sx={{
        borderRadius: '8px', // Bordes más cuadrados
        minWidth: '55x',     // Tamaño más cuadrado
        width: '100px',        // Tamaño más cuadrado
        height: '55px',       // Tamaño más cuadrado
        padding: '15px',   
        backgroundColor:'#0057B8',   
        color:'white'// Ajusta el tamaño del contenido
      }}
      startIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '20px' }} />} onClick={() => setIsOpen(true)}>Crear</Button>
      {isOpen && (
        <div className="overlay">
          <div className="form-container">
            <div className="add-event-form-popup">
              <form onSubmit={handleSubmit}>
                <h1>Crear nuevo Evento</h1>
                <TextField
                  label="Nombre del Evento"
                  variant="outlined"
                  value={formData.nameEvent}
                  onChange={(e) =>
                    setFormData({ ...formData, nameEvent: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Fecha"
                  type="date"
                  variant="outlined"
                  value={formData.date}
                  onChange={(e) =>
                    setFormData({ ...formData, date: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <div style={{ display: "flex" }}>
                    <MobileTimePicker
                      label="Hora Inicio"
                      value={formData.hourInit}
                      onChange={handleHourInitChange}
                    />

                    <MobileTimePicker
                      label="Hora Termino"
                      value={formData.hourEnd}
                      onChange={handleHourEndChange}
                      //renderInput={(params: any) => <TextField {...params} />}
                    />
                  </div>
                </LocalizationProvider>

                <TextField
                  label="Nombre Organizador Evento"
                  variant="outlined"
                  value={formData.organizer}
                  onChange={(e) =>
                    setFormData({ ...formData, organizer: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Turnos Necesarios"
                  variant="outlined"
                  value={formData.shifts}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shifts: parseInt(e.target.value),
                    })
                  }
                  fullWidth
                  margin="normal"
                />
                <br />

                <div className="button-container">
                  <Button type="submit" variant="contained" color="primary">
                    Agregar
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <br />
    </div>
  );
};

export default AddEventForm;
