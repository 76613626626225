// Sidebar.tsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css"; // Importamos el archivo CSS para aplicar los estilos
import avatar from './resources/avatar.jpg'
import servicur from './resources/servicurlogo.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfoCircle,
  faUsersBetweenLines,
  faCalendarCheck,
  faCalendarDays,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { set } from "date-fns";
import { Button , Box, Avatar } from "@mui/material";

const Sidebar: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`sidebar ${expanded ? "expanded" : ""}`}>
      <div className="sidebar-top">
        <Button
          className="expand-button"
          onClick={toggleExpanded}
          variant="contained"
          color="primary"
        >
          {expanded ? "<" : ">"}
        </Button>
        <br />
        <br />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar alt="Avatar" src={ servicur} sx={{ width: expanded ? 100 : 70, height: expanded ? 100 : 70 }} />
                </Box>
      </div>

      <div className="sidebar-mid">
        <ul className="sidebar-list">
          <li>
            <Link to="/">
              <FontAwesomeIcon
                icon={faChartLine}
                size={expanded ? "2x" : "4x"}
              />{" "}
              {expanded ? "Dashboard" : ""}
            </Link>
          </li>
          <br />
          <li>
            <Link to="/Calendar">
              <FontAwesomeIcon icon={faCalendarDays} />{" "}
              {expanded ? "Calendario" : ""}
            </Link>
          </li>
          <br />
          <li>
            <Link to="/Events">
              <FontAwesomeIcon icon={faCalendarCheck} />{" "}
              {expanded ? "Eventos" : ""}
            </Link>
          </li>
          <br />
          <li>
            <Link to="/Workers">
              <FontAwesomeIcon icon={faUsersBetweenLines} />{" "}
              {expanded ? "Trabajadores" : ""}
            </Link>
          </li>
          
        </ul>
      </div>
      <div className="sidebar-bot">
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar alt="Avatar" src={ avatar} sx={{ width: expanded ? 100 : 70, height: expanded ? 100 : 70 }} />
                </Box>
            </div>
    </div>
  );
};

export default Sidebar;
