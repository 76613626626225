// components/Contact.tsx
import React, { useEffect, useState } from "react";
import NewEventsList from "../../components/EventsComponents/NewEventsList/NewEventsComponent";
import EventsProps from "../../models/EventsModel";
import AddEventForm from "../../components/EventsComponents/formEvents/formEvent";
import OldEventsList from "../../components/EventsComponents/OldEvents/OldEventsComponent";
import KPICardComponent from "../../components/EventsComponents/Kpi/KpiComponent";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import Topbar from "../../components/Topbar/Topbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@mui/material/Autocomplete";
import {
  faSearch,
  faTable,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import NewEventsListV2 from "../../components/EventsComponents/NewEventsList/NewEventsComponentV2";

const Events: React.FC = () => {
  //Data
  const newevents: EventsProps[] = [
    {
      date: "27",
      nameEvent: "Fiesta Lolol 2024",
      organizer: "CBProducciones",
      hourInit: "10:00",
      hourEnd: "23:00",
      shifts: 50,
      realShifts: 39,
    },
    {
      date: "28",
      nameEvent: "Festival de Rengo 2024",
      organizer: "Municipalidad de Rengo",
      hourInit: "15:00",
      hourEnd: "00:00",
      shifts: 40,
      realShifts: 23,
    },

    {
      date: "29",
      nameEvent: "Inscripciones Servicio Militar",
      organizer: "Ejercito de Chile",
      hourInit: "8:00",
      hourEnd: "18:00",
      shifts: 10,
      realShifts: 10,
    },

    {
      date: "30",
      nameEvent: "Capacitacion Taekwondo",
      organizer: "Capacitaciones MAT",
      hourInit: "11:00",
      hourEnd: "13:00",
      shifts: 2,
      realShifts: 1,
    },
  ];
  const oldevents: EventsProps[] = [
    {
      date: "21",
      nameEvent: "Sensation XLL",
      organizer: "CBProducciones",
      hourInit: null,
      hourEnd: null,
      shifts: 100,
      realShifts: 2,
    },
    {
      date: "14",
      nameEvent: "Pokemon Tournament 2024",
      organizer: "Game Freak",
      hourInit: null,
      hourEnd: null,
      shifts: 80,
      realShifts: 23,
    },
    {
      date: "7",
      nameEvent: "Capacitacion Taekwondo",
      organizer: "Capacitaciones MAT",
      hourInit: null,
      hourEnd: null,
      shifts: 55,
      realShifts: 40,
    },
  ];

  // Type of newevents list
  interface PropsType {
    booleanValue: boolean;
  }

  const MyComponent: React.FC<PropsType> = ({ booleanValue }) => {
    let divToRender;

    if (booleanValue) {
      divToRender = (
        <div className="newevents-list-container">
          <div className="newevents-listV2">
            <NewEventsListV2 newevents={newevents} />
          </div>
        </div>
      );
    } else {
      divToRender = (
        <div className="newevents-list-container">
          <div className="newevents-list">
            <NewEventsList newevents={newevents} />
          </div>
        </div>
      );
    }

    return <div>{divToRender}</div>;
  };

  // Cargar los datos del localStorage cuando se monte el componente
  // Estado local para almacenar los datos del localStorage
  const [typeList, setTypeList] = useState(false);
  const [savedData, setSavedData] = useState("");
  const [nameEvent, setNameEvent] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [date, setDate] = useState("");
  const [events, setEvents] = useState<EventsProps[]>([]);
  const handleAddEvent = (event: EventsProps) => {
    setEvents([...events, event]);
  };

  useEffect(() => {
    const savedDataString = localStorage.getItem("formData");
    const nameEventString = localStorage.getItem("nameEvent");
    const organizerString = localStorage.getItem("organizer");
    const dateString = localStorage.getItem("date");

    if (savedDataString) {
      setSavedData(savedDataString);
      setNameEvent(nameEventString ?? "");
      setOrganizer(organizerString ?? "");
      setDate(dateString ?? "");
    }
  }, [handleAddEvent]);

  //Autocompletar

  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const eventNames = newevents.map((event) => event.nameEvent);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const filteredEvents = eventNames.filter((eventName) =>
      eventName.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filteredEvents);
  };

  return (
    <div
      style={{
        padding: "5px 5px 5px 30px",
        width: "80vw",
        height: "100vh"
      }}
    >
      <h1>Eventos</h1>
      <div style={{ display: "flex", padding: "20px", width: "82%" }}>
        <KPICardComponent value={"22"} name={"Total Eventos"} />
        <KPICardComponent value={"12"} name={"Proximos"} />
        <KPICardComponent value={"10"} name={"Pasados"} />
      </div>

      <div style={{ display: "flex" }}>
        <Grid container style={{ minHeight: "60vh" }}>
          <Grid item xs={6} md={4}>
            <div style={{ backgroundColor: "white",height:"100%" }}>
              <h2>Eventos Pasados</h2>
              <div className="oldevents-list-container">
                <div className="oldevents-list">
                  <OldEventsList oldevents={oldevents} />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={8}>
            <h2>Proximos Eventos.</h2>
            <div style={{ width: "40vw" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AddEventForm onAddEvent={handleAddEvent} onClose={() => {}} />
                <Autocomplete
                  style={{ width: "100%" }}
                  freeSolo
                  options={filteredSuggestions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Buscar"
                      InputProps={{
                        ...params.InputProps,
                        onChange: handleInputChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon={faSearch} />
                          </InputAdornment>
                        ),
                      }}
                      style={{ marginLeft: "8px", flexGrow: 1 }}
                    />
                  )}
                />
                <Button
                  startIcon={
                    <FontAwesomeIcon
                      icon={typeList ? faTableList : faTable}
                      style={{ fontSize: "20px" }}
                      onClick={() => setTypeList(!typeList)}
                    />
                  }
                ></Button>
              </div>

              <MyComponent booleanValue={typeList} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Events;
